<template>
  <div class="cate">
    <div class="option">
      <div class="title">最新上架</div>

    </div>
    <el-row :gutter="20" class="cate_content">
      <el-col :xs="10" :sm="10" :md="10" :lg="8" :xl="8" v-for="(item, index) in dataList" :key="item.id">
        <Item  :time="item.createTime" :name="item.name"  :currentPage="currentPage" :orderType="'defalut'"
              :size="item.appSize" :star="item.score"  :softId="item.id" :iconUrl="item.logoUrl" :type="item.categoryName"/>
      </el-col>
    </el-row>
    <!--<div class="cate_content">
      <Item v-for="(item, index) in dataList" :key="item.id" :time="item.updateTime" :name="item.name"
            :size="item.appSize" :star="item.score"  :softId="item.id" :iconUrl="item.logoUrl" :type="item.categoryName"/>
    </div>-->
    <div class="page_">
      <el-pagination
              @current-change="currentChange"
              layout="prev, pager, next"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="total">
      </el-pagination>
    </div>


  </div>
</template>

<script>
  import { getNewSoft } from '@/utils/network'
  import Item from '@/components/item/Item.vue'
  export default {
    data() {
      return {
        dataList:[],
        currentPage:this.$store.state.isLastPage?parseInt(this.$store.state.lastPage):1,
        pageSize:12,
        total:null
      }
    },
    created() {
      this.getData()
      this.$store.dispatch('isLastPage', {
        isLastPage: false
      })
    },
    methods: {
      currentChange(index) {
        this.currentPage = index
        this.getData()
        window.scrollTo(0, 0);

      },
      async getData() {
        //console.log(this.cateId)
        const {data: res} = await getNewSoft({
          page:this.currentPage,
          per_page:this.pageSize,
          sourceType:'web'
        })
        if (res.code !== 200) return this.$message.error('请求失败')
       // console.log(res)
        this.dataList = res.data.vodata
        this.total  = res.data.total
        // this.$message.success('获取成功')
      }
    },
    components: {
      Item
    }
  }
</script>

<style lang="less" scoped>
  .cate {
    width: 80%;
    min-height: 670px;
    margin: 20px auto ;
    background-color: #fff;
    overflow: hidden;
    padding: 0 12px;
    .option {
      display: flex;
      //margin: 10px;
      height: 40px;
      line-height: 40px;
      justify-content: flex-start;
      .title {
        font-weight: 700;
        font-size: 18px;
      }
      .op_tol{
        display: flex;
        width: 180px;
        justify-content: space-around;
        font-size: 14px;
        color: rgba(0, 0, 0, .6);
      }
    }

    .cate_content {
      //display: flex;
      //justify-content: space-around;
      flex-wrap: wrap;
      min-height: 545px;
    }
  }

</style>
